import Api from '../api';
const { axios: api } = Api;

export const createConversation = async (payload) => {
  const data = await api.post(
    `customer-conversation/start-conversation`,
    payload,
  );
  return data.data;
};

export const createConversationWord = async (payload) => {
  const data = await api.post(
    `customer-conversation/start-conversation-word`,
    payload,
  );
  return data.data;
};

export const createDailyConversation = async (payload) => {
  const data = await api.post(
    `customer-conversation/create-daily-conversation`,
    payload,
  );
  return data.data;
};

export const createCallModeConversation = async (payload) => {
  const data = await api.post(
    `customer-conversation/create-call-mode-conversation`,
    payload,
  );
  return data.data;
};

export const handleDeleteLessonMessage = async (payload) => {
  const data = await api.delete(
    `customer-conversation/delete-lesson-message/${payload.lessonId}`,
    payload,
  );
  return data.data;
};

export const handleDeleteLessonMessageWord = async (payload) => {
  const data = await api.delete(
    `customer-conversation/delete-lesson-message-word/${payload.lessonId}`,
    payload,
  );
  return data.data;
};
