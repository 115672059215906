import Android1 from './images/Android_1_150_ES.png';
import Android2 from './images/Android_Dodaj_150_ES.png';
import Ios1 from './images/IOS_1_150_ES.png';
import Ios2 from './images/IOS_Dodaj_150_ES.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Restablecer contraseña',
    password: 'Contraseña',
    repeatPassword: 'Repetir contraseña',
    restartPawssord: 'Restablecer contraseña',
  },

  paywall: {
    title: 'Elige un plan para continuar aprendiendo',
    buy: 'Comprar',
    savePayment: 'Pagos seguros gestionados por',
    skip: 'Omitir',
    pickPlan: 'Elegir plan',
    endPlanFirstPart: 'Tu acceso finalizará en',
    endPlanSecondPart: 'días.',
    endPlanAditionalPart: 'día.',
    paymentTitle: 'Resumen',
    product: 'Producto:',
    plan: 'Paquete SynthiAi',
    forLong: 'Acceso por',
    data: 'datos:',
    nameAndSurname: 'Nombre y Apellidos:',
    paymentForm: 'Forma de Pago:',
    toPay: 'A pagar hoy:',
    accept: 'Acepto',
    rules: 'los términos',
    payingAccept: 'Al pagar, aceptas',
    paymentRules: '"Términos de Pago de PayU"',
    buyAndPay: 'COMPRAR Y PAGAR',
  },
  paymentStatus: {
    veryfication: 'El pago aún se está procesando...',
    title: 'Pago completado',
    success: '¡Exitoso!',
    failure: '¡Fallido!',
    subtitle: 'Pronto será redirigido a la página de inicio de sesión.',
    textInfo: `Su pago ha sido procesado exitosamente. Ahora puede configurar su contraseña accediendo a nuestra página de registro. En unos minutos, recibirá un correo electrónico con un enlace que le permitirá configurar su contraseña.
Por favor, revise su bandeja de entrada, así como la carpeta de spam. Para facilitar la búsqueda, escriba "synthiai" en el campo de búsqueda. Si tiene algún problema, contáctenos en: contact@synthiaiapp.com`,
    login: 'Ir a iniciar sesión',
  },
  register: {
    steps: {
      register: 'Registro',
      package: 'Paquete',
      summarize: 'Resumen',
      step: 'Paso ',
    },
    stepOne: {
      password: 'Contraseña',
      errorGoogle: 'Conéctese con su correo electrónico',
      title: 'Registro',
      nameAndSurname: 'Nombre y Apellido',
      placeholderNameAndSurname: 'XXXXXXXXXXX',
      email: 'Correo electrónico',
      placeholderEmail: 'ej. ejemplo@synthiaiapp.com',
      password: 'Contraseña',
      start: 'CREAR CUENTA',
      subtitle:
        'La contraseña debe tener al menos 8 caracteres, incluyendo letras minúsculas y mayúsculas, y un número.',
      passwordError1: 'La contraseña debe contener al menos 8 caracteres.',
      passwordError2: 'La contraseña debe contener al menos 1 letra mayúscula.',
      passwordError3: 'La contraseña debe contener al menos 1 letra minúscula.',
      passwordError4: 'La contraseña debe contener al menos 1 número.',
      passwordError5: 'Las contraseñas deben coincidir.',
    },
    stepTwo: {
      title: 'Elige tu plan',
      month: 'mes',
      months: 'meses',
      months2: 'meses',
      value: '€ / mes',
      pick: 'elegir',
      nowPay: 'Pago inmediato',
      saveOne: 'Guardar',
      buy: 'Comprar',
      savePayment: 'Pagos seguros procesados por',
    },
    stepThree: {
      title: 'Resumen',
      product: 'Producto:',
      plan: 'Paquete Synthi AI',
      forLong: 'Acceso durante',
      data: 'fecha:',
      nameAndSurname: 'Nombre y Apellido:',
      paymentForm: 'Forma de pago:',
      toPay: 'Pagar:',
      accept: 'Aceptar',
      rules: 'términos y condiciones',
      payingAccept: 'Al pagar aceptas los',
      paymentRules: '"Términos de pago de PayU"',
      buyAndPay: 'COMPRAR Y PAGAR',
    },
  },
  succesPayment: {
    title: '¡Pago exitoso!',
    subtitle: 'En breve serás redirigido a la página de inicio de sesión.',
  },
  firstLogin: {
    createPasswordForAcoount: 'Cree una contraseña para su cuenta',
    typeEmail: 'Introduzca su dirección de correo electrónico',
    useSameEmail:
      'Use la misma dirección que proporcionó al realizar la compra',
    typePassword: 'Introduzca su contraseña',
    confirmPassword: 'Confirme su contraseña',
    createPassword: 'Crear Contraseña',
    errorLogin: 'Verifique si su contraseña o correo electrónico son correctos',
    createdPassword: '¿Ya has creado una contraseña?',
    clickToLogin: 'Haz clic aquí para iniciar sesión',
  },
  login: {
    dontHavePassword: '¿Aún no tienes contraseña?',
    getPassword: 'Haz clic aquí para obtener acceso',
    title: 'Iniciar sesión',
    email: 'Correo electrónico',
    password: 'Contraseña',
    textSub: '¿Olvidaste tu contraseña? Restablecer',
    restart: 'aquí',
    restartPasswordText:
      'Introduce tu correo electrónico para restablecer tu contraseña. La contraseña se enviará a tu bandeja de entrada.',
    placeholder: 'introduce tu correo...',
    resetPassword: 'restablecer contraseña',
    buttonText: 'Iniciar sesión',
    errorLogin:
      'Por favor, verifica que tu contraseña o correo electrónico sean correctos',
    passwordResterted:
      'Tu contraseña ha sido restablecida. Revisa tu correo electrónico.',
    passwordError: 'Lo siento, no pudimos encontrar tu correo electrónico.',
  },
  navBar: {
    home: 'Start',
    learn: 'Aprende',
    profile: 'Perfil',
  },
  home: {
    hello: 'Hola',
    question: '¿Sobre qué te gustaría hablar hoy?',
    answer: 'Comienza una conversación',
    forYou: 'Recomendado para ti',
    popularNow: 'Popular ahora',
    doYouKnow: '¿Sabías que...?',
    funFact: 'Dato curioso del día',
    new: 'Nuevo',
    newTitle: 'Escenas',
    newSubtitle:
      'Actúa y representa una escena con un tutor de IA sobre el tema que elijas.',
    yourFavorite: 'Tus favoritos',
  },
  learn: {
    chat: {
      title: 'Chat',
      subtitle: 'Conversa sobre cualquier tema.',
    },
    fotoMode: {
      title: 'Adivina la foto',
      subtitle: 'Describe la imagen lo más detalladamente posible.',
    },
    scene: {
      title: 'Escenas',
      subtitle: 'Actúa con un tutor de IA.',
      new: 'Nuevo',
    },
    word: {
      title: 'Palabras',
      subtitle: 'Amplia tu vocabulario.',
    },
    flashcard: {
      title: 'Tarjetas de vocabulario',
      subtitle: 'Repasa y memoriza palabras.',
    },
    seeMore: 'Ver todos',
  },
  common: {
    description:
      'SynthiAi - Tu Asistente Inteligente para el Aprendizaje de Inglés. Aprende a hablar inglés con fluidez con la ayuda de la inteligencia artificial. Descubre un nuevo enfoque efectivo para el aprendizaje de idiomas. ¡Comienza hoy mismo!',
    deletedFromFavorite: 'Eliminado de favoritos',
    addedToFavorite: 'Añadido a favoritos',
    flashcards: 'Tarjetas de memoria',
    scenes: 'Escenas',
    words: 'Palabras',
    privacyLink: 'privacy',
    termsLink: 'tos',
    or: 'o',
    continueWithFB: 'Seguimiento en Facebook',
  },
  profile: {
    days: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
    top: {
      profile: 'Perfil',
      accessTo: 'Acceso a:',
      profileImage: 'Foto de perfil',
      nameAndSurname: 'Nombre y Apellido',
      password: 'Contraseña',
      change: 'Cambiar',
      changeProfileImage: 'Cambiar foto de perfil',
      changeNameAndSurname: 'Cambiar nombre y apellido',
      changeNameAndSurnamePlaceHolder: 'Introduce tu nombre y apellido',
      save: 'Guardar',
      changePassword: 'Cambiar contraseña',
      passwordInfo:
        'La contraseña debe tener al menos 8 caracteres, incluyendo letras minúsculas y mayúsculas, y un número',
      repeatPassword: 'Repetir contraseña',
      passwordChanged: 'Contraseña cambiada',
      error: 'Oops, algo salió mal',
      nameChanged: 'Nombre cambiado',
      profileChanged: 'Perfil cambiado',
    },
    plan: {
      yourPlan: 'Tu plan',
      extend: 'Extiende tu plan',
      short_extend: 'Extiende',
      currentPlan: 'Plan actual:',
      activeToPlan: 'Activo hasta:',
      currentPlanLong: 'Tu plan actual',
    },
    middle: {
      yourMedals: 'Tus logros',
      oneDay: 'día',
      twoDays: 'días',
      words: 'Palabras',
      learningTime: 'Tiempo de aprendizaje',
      streak: 'Racha',
      streakRecord: 'Récord de racha',
      yourRecord: 'Tu récord:',
      currentRecord: 'Récord actual:',
      wordsInWeek: 'Palabras de la semana:',
    },
    bottom: {
      yourTutor: 'Tu tutor de IA',
      change: 'Cambiar',
      logout: 'Cerrar sesión',
      FAQ: 'Preguntas frecuentes',
      shareOpinion: 'Comparte tu opinión',
      yourPlan: 'Tu plan',
      hobby: 'Aficiones',
      learningSetting: 'Configuración de aprendizaje',
      back: 'Atrás',
      dayTarget: 'Objetivo diario',
      languageLevel: 'Nivel de idioma',
      changeYourLangLevel: 'Cambia tu nivel de idioma.',
      pickNewTutor: 'Elige un nuevo tutor',
      yourDecision:
        'Tu decisión afectará al estilo y ambiente de las lecciones.',
      tutroChanged: 'Tutor cambiado',
      error: 'Oops, algo salió mal',
      englishLevelChanged: 'Tu nivel ha sido cambiado',
      levelsOptions: [
        {
          name: 'Principiante A1',
          description: 'Empieza tu aventura con el inglés',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Básico A2',
          description:
            'Puedes entender información básica en conversaciones simples y cotidianas',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Intermedio B1',
          description:
            'Puedes mantener conversaciones simples pero comprensibles sobre temas conocidos.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Avanzado B2',
          description:
            'Puedes comunicarte con fluidez en la mayoría de las situaciones',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Experto C1+',
          description:
            'Puedes utilizar el idioma con eficacia en contextos complicados',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Cambia tu objetivo diario de aprendizaje.',
      deletedFromFavorite: 'Eliminado de favoritos',
      addedToFavorite: 'Añadido a favoritos',
      lessonTime: [
        {
          name: 'Lección rápida',
          dsecription: 'Opción ideal para un aprendizaje exprés',
          time: '5 min',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Sesión estándar',
          dsecription: 'Excelente opción para un aprendizaje equilibrado',
          time: '10 min',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Práctica intensiva',
          dsecription: 'La mejor opción para los entusiastas',
          time: '15 min',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: '¡Objetivo diario cambiado!',
      pickIntresets: 'Elige al menos 3 aficiones.',
      activeTo: 'Plan activo hasta:',
      daysLeft: 'Días restantes del plan:',
      opinionSubtitle:
        'Estamos mejorando constantemente nuestra aplicación. Comparte tu opinión sobre lo que podríamos cambiar en la aplicación o lo que más te gusta.',
      startTyping: 'Empieza a escribir',
      faq: 'Preguntas frecuentes',
      send: 'Enviar',
      withProblems: 'Si tienes preguntas/problemas, contáctanos:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'El sonido no funciona.',
          answer: `Si tienes problemas con el sonido, considera los siguientes consejos: 
      
              Verifica los permisos de audio en tu navegador.
      
              Asegúrate de que el volumen de tu dispositivo sea adecuado.
      
              Verifica que los altavoces/audífonos estén conectados correctamente.
      
              Revisa la configuración de audio del sistema para ver si las aplicaciones están silenciadas.
      
              Prueba con otro dispositivo o contacta con soporte técnico.
          `,
        },
        {
          id: 2,
          question: 'No puedo grabar mi voz.',
          answer: `Si tienes problemas para grabar audio, considera los siguientes consejos: 
              
              Verifica los permisos del micrófono en tu navegador.
      
              Asegúrate de que el navegador móvil tenga acceso al micrófono.
      
              Verifica que el micrófono esté conectado correctamente.
      
              Considera usar otro dispositivo o contacta con soporte técnico.
          `,
        },
        {
          id: 3,
          question: '¿Puedo usar la aplicación en más de un dispositivo?',
          answer: `Por supuesto, simplemente inicia sesión en cualquier dispositivo compatible para disfrutar de una experiencia de aprendizaje fluida.`,
        },
        {
          id: 4,
          question: '¿Puedo usar Synthi AI sin conexión a internet?',
          answer: `No, Synthi AI requiere un navegador web con acceso a internet.`,
        },
        {
          id: 5,
          question: '¿Cómo puedo cambiar el tutor de IA?',
          answer: `Ve a la pestaña "Tutor" en la configuración de Synthi AI. Explora y elige el tutor que prefieras.`,
        },
        {
          id: 6,
          question:
            '¿Cómo puedo renovar mi acceso a la aplicación después de que expire?',
          answer: `Puedes renovar tu plan iniciando sesión después de que expire.`,
        },
        {
          id: 7,
          question: '¿Puedo obtener una factura?',
          answer: `Contacta con contact@synthiaiapp.com proporcionando tu NIF y los datos de tu empresa.`,
        },
        {
          id: 8,
          question: '¿Cómo puedo contactar con soporte técnico?',
          answer: `Envía un correo electrónico a contact@synthiaiapp.com con los detalles del problema, información sobre el dispositivo y el navegador.`,
        },
      ],
    },
    rules: 'términos y condiciones',
    privacy: 'Política de privacidad',
    span: ' y ',
  },
  welcome: {
    languagePicker: {
      title: 'Elige el idioma que quieres aprender',
      next: 'Siguiente',
      langToLearn: 'Idioma de aprendizaje',
      successTitle: 'El idioma de aprendizaje ha sido cambiado',
      config: [
        {
          label: 'Inglés',
          label2: 'Inglés',
          labelInside: 'inglés',
          value: 'en',
        },
        {
          label: 'Alemán',
          label2: 'Alemán',
          labelInside: 'alemán',
          value: 'de',
        },
        {
          label: 'Español',
          label2: 'Español',
          labelInside: 'español',
          value: 'es',
        },
        {
          label: 'Francés',
          label2: 'Francés',
          labelInside: 'francés',
          value: 'fr',
        },
        {
          label: 'Italiano',
          label2: 'Italiano',
          labelInside: 'italiano',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: '¡Bienvenido a la aplicación Synthi AI!',
      start: 'EMPEZAR',
      welcomeMobile: 'Bienvenido',
      config: {
        chrome: {
          title: 'Instala la aplicación en tu dispositivo Android.',
          text1: 'En el navegador Chrome, haz clic en',
          text1bold: ' Configuración',
          image1: Android1,
          text2: 'Luego haz clic en',
          text2bold: ' Añadir a pantalla de inicio',
          image2: Android2,
          text3: '¡Listo! Synthi AI ahora está en tu pantalla de inicio',
          image3: Last,
        },
        safari: {
          title: 'Instala la aplicación en tu dispositivo iOS.',
          text1: 'En el navegador Safari, haz clic en',
          text1bold: ' Compartir',
          image1: Ios1,
          text2: 'Luego haz clic en',
          text2bold: ' Añadir a pantalla de inicio',
          image2: Ios2,
          text3: '¡Listo! Synthi AI ahora está en tu pantalla de inicio',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: '¡Elige tu tutor!',
      subtitle:
        'Tu elección afectará el estilo y la atmósfera de las lecciones.',
      next: 'SIGUIENTE',
    },
    secondScreen: {
      title: '¿Cuál es tu nivel actual de {language}?',
      config: [
        {
          name: 'Básico A1',
          description: 'Empieza tu aventura con el {language}.',
          value: 'basic',
        },
        {
          name: 'Básico A2',
          description:
            'Puedes entender información básica en conversaciones simples y cotidianas',
          value: 'beginner',
        },
        {
          name: 'Intermedio B1',
          description:
            'Puedes mantener conversaciones simples pero comprensibles sobre temas conocidos.',
          value: 'intermediate',
        },
        {
          name: 'Avanzado B2',
          description:
            'Puedes comunicarte con fluidez en la mayoría de las situaciones',
          value: 'advanced',
        },
        {
          name: 'Experto C1+',
          description:
            'Puedes utilizar el idioma con eficacia en contextos complicados',
          value: 'expert',
        },
      ],
      next: 'SIGUIENTE',
    },
    thirdScreen: {
      title: '¿Cuáles son tus aficiones?',
      subtitle: 'Elige al menos 3 aficiones.',
      next: 'SIGUIENTE',
    },
    fourthScreen: {
      title: '¿Cuántos minutos al día quieres estudiar?',
      subtitle: 'Elige tu objetivo diario.',
      next: '¡EMPEZAMOS!',
      config: [
        {
          name: 'Lección rápida',
          dsecription: 'Opción ideal para un aprendizaje exprés',
          time: '5 min',
          value: 'five_minutes',
        },
        {
          name: 'Sesión estándar',
          dsecription: 'Excelente opción para un aprendizaje equilibrado',
          time: '10 min',
          value: 'ten_minutes',
        },
        {
          name: 'Práctica intensiva',
          dsecription: 'La mejor opción para los entusiastas',
          time: '15 min',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Atrás',
    },
    fotoMode: {
      welcome: {
        title: 'Foto adivinanza ',
        subtitle:
          'Describe lo que ves en la imagen con la mayor cantidad de detalles posible en el tema seleccionado.',
        start: '¡Comenzar!',
      },
      info: {
        startTyping: 'Empieza a escribir...',
        newTask: 'Nueva tarea',
        send: 'Enviar',
      },
    },
    flashcards: {
      title: 'Tarjetas',
      subtitleTop:
        'Aprende y memoriza nuevas palabras a través de una forma interactiva de estudio con tarjetas.',
      addCategory: 'Añade tu categoría',
      subtitle: 'Escribe el nombre de tu categoría',
      type: 'escribe la categoría...',
      add: 'Añadir',
      previous: 'Tarjeta anterior',
      task: 'Tarea',
      changeLevel: 'Cambiar nivel',
      dontKnow: 'No lo sé',
      know: 'Lo sé',
      pickPairs: 'Conectar pares',
      goodJob: '¡Buen trabajo!',
      titleEnd: 'Tu dedicación es impresionante',
      subtitleEnd: '¿Estás listo para más desafíos?',
      repeat: 'Repetir categoría',
      other: 'Elige otra categoría',
      level: {
        title: 'Cambiar nivel de tarjeta',
        beginner: ' Principiante A1-A2',
        intermediate: 'Intermedio B1-B2',
        advance: 'Avanzado C1-C2',
        change: 'Cambiar',
      },
    },
    scene: {
      title: 'Escenas',
      subtitle:
        'Ponte en el papel y representa una escena con un tutor de IA sobre el tema seleccionado.',
      suggest: 'Sugerir nueva escena',
      talk: 'Hablar',
      deletedFromFavorite: 'Eliminado de favoritos',
      addedToFavorite: 'Añadido a favoritos',
    },
    word: {
      title: 'Palabras',
      subtitle:
        'Amplía tu vocabulario en áreas clave en conversaciones con un tutor de IA.',
      suggest: 'Sugerir nuevo tema de palabras',
      deletedFromFavorite: 'Eliminado de favoritos',
      addedToFavorite: 'Añadido a favoritos',
    },
  },
  cookie: {
    title: 'Información sobre cookies',
    subtitle:
      'Este sitio web utiliza cookies para proporcionarte una mejor experiencia de usuario y ofrecerte contenido personalizado. Si quieres obtener más información al respecto, consulta nuestro soporte tXXXXcnico ',
    link: 'política de privacidad',
    accept: 'ACEPTAR',
    denied: 'RECHAZAR',
  },
  talkingBar: {
    allowMicrofon: 'Por favor, permite el acceso al micrófono',
    typeMessage: 'Escribe un mensaje...',
    record: 'Grabar',
    recording: 'Grabando',
  },
  hands: {
    giveUsInfo: 'Danos tu opinión',
    thanksForGrading: '¡Gracias por calificar!',
    subtitle: '¡Tu voz es importante para nosotros!',
    subtitle2: 'Cuéntanos qué piensas sobre esta lección:',
    skip: 'OMITIR',
    send: 'ENVIAR',
  },
  suggestConfig: {
    profile: {
      title: '¡Comparte tu opinión!',
      subtitle:
        'Estamos mejorando constantemente nuestra aplicación. Comparte tu opinión sobre lo que podríamos cambiar en la aplicación o lo que más te gusta.',
    },
    word: {
      title: 'Sugerir nuevo tema de palabras',
      subtitle: '',
    },
    scene: {
      title: 'Sugerir nueva escena',
      subtitle: '',
    },
    startTyping: 'Empieza a escribir',
    send: 'ENVIAR',
    thx: '¡Opinión enviada! ¡Gracias!',
    error: '¡Ups! Algo salió mal',
  },
  checkout: {
    emailGuessText: '¿Quisiste decir',
    leftTitle: 'Elija un plan para usted',
    rightTitle: 'Completar los datos',
    subscribe: 'suscribirse',
    currencySign: '€',
    currency: 'eur',
    savePayments: 'Los pagos seguros son gestionados por',
  },
  dailyV2: {
    hint: {
      title: 'Pista',
      pick: 'Elegir',
    },
    feedback: {
      yourMessage: 'Tu mensaje',
      goodMessage: 'Mensaje correcto',
      explanation: 'Explicación',
    },
    tutorial: {
      title: '¿Cómo usar el chat?',
      skip: 'Saltar',
      next: 'SIGUIENTE',
      start: 'Comenzar',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: 'Haga clic para silenciar el sonido del Tutor',
            image: VolumeUp,
          },
          { title: 'Haga clic para dar su opinión', image: HandsUp },
          { title: 'Haz clic para abrir las instrucciones', image: Tutorial },
        ],
        [
          { title: 'Haga clic para volver a escuchar', image: VolumeUpBlank },
          {
            title: 'Haga clic para ver la traducción',
            image: TranslationBlank,
          },
          { title: 'Haga clic para ver la pista', image: HintBlank },
          { title: 'Haga clic para informar un problema', image: InfoBlank },
        ],
        [
          { title: 'Tu respuesta es correcta', image: GreenCircle },
          {
            title: 'Haga clic para ver la respuesta corregida y la explicación',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Haga clic en el campo de texto para escribir un mensaje' },
          { title: 'Haga clic para grabar un mensaje', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Informar de un problema',
    subtitle: 'Describe el problema relacionado con el mensaje de IA',
    send: 'Enviar',
    messageSend: 'Mensaje enviado',
    error: 'Algo salió mal, escríbenos :)',
  },
};
